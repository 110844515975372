/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";

// Amplify.configure({ Auth: AWSAuthConfigDEP });

export const onRenderBody = ({ setHeadComponents }) => {
	setHeadComponents([
		<script
			key="bootstrap"
			src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
			integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
			crossOrigin="anonymous"
			async
		/>,
	]);
};

export const wrapRootElement = ({ element }) => {
	console.log("PCI - Gatsby - browser");
	// return (<Provider store={store}>
	// 	<PersistGate loading={null} persistor={persistor}>
	// 		<AwsAuthProvider>
	// 			{element}
	// 		</AwsAuthProvider>
	// 	</PersistGate>
	// </Provider>);
};

export const onRouteUpdate = () => {
	if(typeof window !== 'undefined'){
	window.locations = window.locations || [document.referrer];
	window.locations.push(window.location.href);
	window.previousPath = window.locations[window.locations.length - 2];
	}
};